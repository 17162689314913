<template>
    <div class="ic-bill-info">
        <div class="flex-between info-header mb20">
            <div>
                <a-icon type="appstore" theme="twoTone" />
                需求编号: {{ needSn }}
            </div>
            <div>
                <a-icon type="clock-circle" theme="twoTone" />
                发布时间: {{ publishTime }}
            </div>
            <div>
                <a-icon type="info-circle" theme="twoTone" />
                需求状态:
                <div class="inline-block">
                    <span :style="{ color: statusData.color }">
                        {{ statusData.display }}
                    </span>
                </div>
            </div>
        </div>

        <div>
            <IReadCol
                :cols="1"
                :items="billItems"
                :source="$needIDData"
                :label-width="180"
            />
        </div>

        <div
            class="mb10"
            style="
                border-top: 1px dashed rgb(81 90 110 / 27%);
                margin: 10px 30px;
            "
        />
        <div class="ic-bill-info-bottom">
            <div class="flex-between mb10">
                <div class="bold c-black flex-v-center">
                    <span class="c-gray field-title">联系人:</span>
                    {{ contactName }}
                </div>
            </div>
            <div class="flex-between mb10">
                <div class="bold c-black flex-v-center">
                    <span class="c-gray field-title">联系电话: </span>
                    {{ contactPhone }}
                </div>

                <div v-if="status === 2" class="c-red">
                    <CountDown :end-time="endTime" />
                </div>
            </div>
            <div class="flex-between mb10">
                <div class="bold c-black flex-v-center">
                    <span class="c-gray field-title">地址:</span>
                    {{ receiverDetailAddress }}
                </div>
                <div class="flex-v-center">
                    <Button class="mr10" @click="handleBack">返回</Button>
                    <OnceMore :row="$needIDData" />
                    <Delay :row="$needIDData" />
                    <Cancel :row="$needIDData" />
                    <Publish :row="$needIDData" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 询货内容
import OnceMore from './BillInfo/OnceMore.vue';
import Delay from './BillInfo/Delay.vue';
import Cancel from './BillInfo/Cancel.vue';
import Publish from './BillInfo/Publish.vue';
import CountDown from '../../../../components/CountDown.vue';
import ViewImg from '@/components/ViewImg.vue';
import needGetByIDMixin from '@/mixins/needGetByID.mixin';
import IReadCol from "@/components/ui/IReadCol.vue";
import currentOrgMixin from "@/mixins/currentOrg.mixin";

const statusMap = $const.needStatus;
export default {
    components: {
        IReadCol,
        ViewImg,
        CountDown,
        Publish,
        Cancel,
        Delay,
        OnceMore,
    },
    mixins: [needGetByIDMixin, currentOrgMixin],
    data() {
        return {
            options: {
                productCategoryType: [...$const.options.productCategoryType],
                priorityType: [...$const.options.priorityType],
                purchasePlatform: [...$const.options.purchasePlatform],
                expireTimeType: [...$const.options.expireTimeShowType],
            },
        };
    },
    computed: {
        billItems() {
            window._billInfo = this;
            let isKeyanJigou = +_.get(this.$needIDData, 'orgQualificationId') === 1;
            console.log(this.$needIDData, '===');

            return [
                {
                    title: '品牌:',
                    render: () => {
                        if (this.orderType === 1 || this.orderType === 3) {
                            return (
                                <div>
                                    {this.productBrand}
                                </div>
                            );
                        }

                        return this.$emptyContent;
                    }
                },
                {
                    title: '货号:',
                    render: () => {
                        if (this.orderType === 1) {
                            return (
                                <div>
                                    {this.productNo}
                                </div>
                            );
                        }

                        return this.$emptyContent;
                    }
                },
                {
                    title: '名称:',
                    render: () => {
                        if (this.orderType === 2) {
                            return (
                                <div>
                                    {this.productName}
                                </div>
                            );
                        }

                        return this.$emptyContent;
                    }
                },
                {
                    title: '图片:',
                    render: () => {
                        if (this.orderType === 3 && this.productPic) {
                            return (
                                <ViewImg src={this.productPic} />
                            );
                        }

                        return this.$emptyContent;
                    }
                },
                {
                    title: '商品类型:',
                    path: 'needItemList.0.productCategoryType',
                    select: {
                        options: $const.options.productCategoryType
                    }
                },
                {
                    title: '采购数量:',
                    render: () => {
                        let q = this.get$needItem(this.$needIDData, 'productQuantity');
                        let unit = this.get$needItem(this.$needIDData, 'unit');
                        let specs = this.get$needItem(this.$needIDData, 'specs');
                        return q + (unit || specs || '');
                    }
                },
                {
                    title: '采购优先项:',
                    path: 'priorityType',
                    select: {
                        options: $const.options.priorityType
                    }
                },
                {
                    title: '需走订单流程平台:',
                    path: 'purchasePlatform',
                    hide: !isKeyanJigou,
                    select: {
                        options: $const.options.purchasePlatform
                    }
                },
                {
                    title: '终端收货地址:',
                    path: 'receiverTerminalAddress',
                },
            ]
        },
        orderType() {
            return +this.$needIDData.orderType;
        },
        needSn() {
            return this.$needIDData.needSn;
        },
        publishTime() {
            return $util.format$Date(this.$needIDData.publishTime)
        },
        productBrand() {
            return this.get$needItem(this.$needIDData, 'productBrand');
        },
        productNo(){
            return this.get$needItem(this.$needIDData, 'productNo');
        },
        productName(){
            return this.get$needItem(this.$needIDData, 'productName');
        },
        productPic(){
            return this.get$needItem(this.$needIDData, 'productPic');
        },

        statusData() {
            if (this.$needIDData.statusMsg) {
                return {
                    color: '#e54545',
                    display: this.$needIDData.statusMsg,
                };
            }

            return (
                _.get(statusMap, [this.$needIDData.status]) || {
                    display: '--',
                    color: 'red',
                }
            );
        },
        expireTimeType() {
            let value = this.$needIDData.expireTimeType;
            return $util.getOptName(value, $const.options.expireTimeShowType);
        },
        contactName() {
            return _.get(this.$needIDData, 'contactName');
        },
        contactPhone() {
            return _.get(this.$needIDData, 'contactPhone');
        },
        status() {
            return +_.get(this.$needIDData, 'status');
        },
        endTime() {
            return dayjs(this.$needIDData.publishTime).add(
                this.$needIDData.expireTimeType,
                'minute',
            );
        },
        receiverDetailAddress() {
            return _.get(this.$needIDData, 'receiverDetailAddress');
        },
    },
    methods: {
        handleBack() {
            this.$router.back();
        },
    },
};
</script>
<style lang="less" scoped>
.ic-bill-info {
    .info-header {
        line-height: 48px;
        background: #f7f7f7;
        border-radius: 2px;
        padding: 0 40px;
        font-weight: bold;
        color: #000;
    }
}
</style>
