<template>
    <div>
        <Button
            v-if="!disabled"
            class="mr10"
            type="primary"
            @click="handleEdit1(row)"
        >
            再来一单
        </Button>
    </div>
</template>
<script>
export default {
    props: {
        row: {},
    },
    computed: {
        disabled() {
            let status = +this.row.status;
            return (
                status !== 4 &&
                status !== 5 &&
                status !== 6 &&
                status !== 7 &&
                status !== 9
            );
        },
    },
    methods: {
        handleEdit1(row) {
            this.$router.push({
                path: '/home/buyer/bill',
                query: {
                    id: row.id,
                    tryOne: 1,
                },
            });
        },
    },
};
</script>
