const _wrapStyle = {
    height: '32px',
    fontSize: '14px'
};

const _titleStyle = {
    color: '#515a6e',
};
const _contentStyle = {
    color: 'black',
};
const classWrap = 'flex-v-center';

export default {
    name: 'IReadColItem',
    functional: true,
    props: {
        source: Object,
        item: Object,
        labelWidth: Number,
    },
    render: (h, ctx) => {
        let {item, labelWidth, source} = ctx.props;
        if (item.hide) {
            return null;
        }

        let titleStyle = {
            ..._titleStyle,
            width: labelWidth + 'px'
        };

        let contentStyle = {
            ..._contentStyle,
        };
        let title = _.isFunction(item.title) ? item.title(h, item) : item.title;


        if (item.render) {
            return (
                <div class={classWrap} style={_wrapStyle}>
                    <div class={'mr10 tr'} style={titleStyle}>{title}</div>
                    <div style={contentStyle}>{item.render(h, item)}</div>
                </div>
            );
        }

        let content = item.content;
        if (source && item.path) {
            content = _.get(source, item.path);
        }

        if (_.isFunction(item.formatter)) {
            content = item.formatter(content, item);
        }

        if (item.type === 'img') {
            return (
                <div class={classWrap} style={_wrapStyle}>
                    <div class={'mr10 tr'} style={titleStyle}>{title}</div>
                    <div style={contentStyle}>
                        {content && <img style={{maxHeight: '30px', maxWidth: '100%'}} src={content} alt={content}/>}
                    </div>
                </div>
            );
        }


        // try option:value
        if (item.select) {
            let {
                options = [],
                value = 'value',
                display = 'text'
            } = item.select;

            let it = options.find(it => {
                // 字符串or数字匹配
                return it[value] === content || it[value] - content === 0;
            });
            if (it) {
                content = it[display] || content;
            }
        }

        return (
            <div class={classWrap} style={_wrapStyle}>
                <div class={'mr10 tr'} style={titleStyle}>{title}</div>
                <div style={contentStyle}>{content}</div>
            </div>
        );
    }
};

