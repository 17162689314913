<template>
    <div>
        <Row
            v-for="it of groupItems"
            :key="it.uniqId"
            :data-key="it.uniqId"
            class="mb10"
        >
            <Col
                v-for="item of it.children"
                :key="item.uniqId"
                :span="span"
                :data-key="item.uniqId"
            >
                <IReadColItem
                    :item="item"
                    :label-width="labelWidth"
                    :source="source"
                />
            </Col>
        </Row>
    </div>
</template>

<script>
import IReadColItem from './IReadCol/Item';
// items: [
//     {
//         title: '标题:',
//         content: '内容',
//         render: h => {
//             return h('span', 'render: content')
//         }
//     },
//     {
//         title: () => 'render标题:',
//         content: 123,
//     },
//     {
//         title: 'path内容:',
//         path: 'name',
//     },
//     {
//         title: 'pathIMG:',
//         path: 'url',
//         type: 'img'
//     },
//     {
//         title: '标题:',
//         content: 'https://www.baidu.com/img/flexible/logo/pc/result@2.png',
//         // path: '',
//         type: 'img',
//     }, {
//         title: '标题',
//         content: '内容',
//         render: h => {
//             return h('span', '88998')
//         }
//     },
//     {
//         type: 'empty'
//     },
//     {
//         title: '图片',
//         content: '-',
//     },
//     {
//         title: 'optionValue:',
//         content: '2',
//         select: {
//             options: $const.options.priorityType,
//             value: 'value',
//             display: 'text'
//         }
//     },
// ]
export default {
    name: 'IReadCol',
    components: { IReadColItem },
    props: {
        cols: {
            default: 3,
        },
        labelWidth: {
            default: 120,
        },
        source: {},
        items: {
            default: () => [],
        },
    },
    computed: {
        span() {
            return 24 / this.cols;
        },
        groupItems() {
            let ret = [];
            let t = [];

            this.items.forEach((it) => {
                if (!it.uniqId) {
                    it.uniqId = $util.getUniqId();
                }

                if (t.length >= this.cols) {
                    ret.push({
                        uniqId: t.map((it) => it.uniqId).join(','),
                        children: t,
                    });
                    t = [];
                }

                t.push({ ...it });
            });

            if (t.length) {
                ret.push({
                    uniqId: t.map((it) => it.uniqId).join(','),
                    children: t,
                });
            }

            return ret;
        },
    },
};
</script>
