<template>
    <div>
        <Table border :columns="columnsRet" :data="data">
            <template slot="productPic" slot-scope="{ row }">
                <ViewImg
                    v-if="row.needItemList[0] && row.needItemList[0].productPic"
                    :src="row.needItemList[0].productPic"
                />
                <span v-else>--</span>
            </template>
            <template slot="action" slot-scope="{ row }">
                <span
                    class="pointer"
                    :style="getViewStyleText(row).style"
                    @click="handleView(row)"
                >
                    {{ getViewStyleText(row).text }}
                </span>
            </template>
        </Table>
        <div class="flex-end">
            <Page
                :total="pageOpts.total"
                :page-size="pageOpts.size"
                :current="pageOpts.current"
                show-sizer
                show-elevator
                class="mt10"
                @on-change="pageChange"
                @on-page-size-change="pageSizeChange"
            />
        </div>
    </div>
</template>
<script>
import ViewImg from '@/components/ViewImg.vue';

export default {
    components: { ViewImg },
    props: {
        tabType: {},
        formQuery: {},
    },
    data() {
        return {
            columns: [
                {
                    title: '发单时间',
                    key: 'createTime',
                    width: 120,
                    render(h, { row }) {
                        return h(
                            'span',
                            dayjs(_.get(row, 'createTime')).format(
                                'YYYY-MM-DD',
                            ),
                        );
                    },
                },

                // {
                //     title: '买家类型',
                //     key: 'types',
                //     width: 100,
                //     render(h, { row }) {
                //         return h('span', _.get(row, 'orgQualification'));
                //     },
                // },
                // {
                //     title: '买家区域',
                //     width: 300,
                //     // key: 'receiverDetailAddress',
                //     key: 'receiverTerminalAddress',
                // },
                {
                    title: '品牌',
                    key: 'productBrand',
                    width: 140,
                    render: (h, { row }) => {
                        let text = _.get(row, 'needItemList.0.productBrand');
                        if (!text) {
                            return (
                                <span>{this.$emptyContent}</span>
                            );
                        }
                        return (
                            <Tooltip placement="left" content={text} class="wp100">
                                <div class="ellipse">{text}</div>
                            </Tooltip>
                        );
                    },
                },
                {
                    title: '货号',
                    key: 'productNo',
                    width: 140,
                    render: (h, { row }) => {
                        let text = _.get(row, 'needItemList.0.productNo');
                        if (!text) {
                            return (
                                <span>{this.$emptyContent}</span>
                            );
                        }
                        return (
                            <Tooltip placement="left" content={text} class="wp100">
                                <div class="ellipse">{text}</div>
                            </Tooltip>
                        );
                    },
                },
                {
                    title: '名称',
                    key: 'productName',
                    width: 140,
                    render: (h, { row }) =>{
                        let text = _.get(row, 'needItemList.0.productName');

                        if (!text) {
                            return (
                                <span>{this.$emptyContent}</span>
                            );
                        }
                        return (
                            <Tooltip placement="left" content={text} class="wp100">
                                <div class="ellipse">{text}</div>
                            </Tooltip>
                        );
                    },
                },

                {
                    title: '图片',
                    width: 100,
                    resizable: true,
                    tooltip: true,
                    key: 'productPic',
                    slot: 'productPic',
                },

                {
                    title: '商品数量',
                    key: 'productQuantity',
                    width: 100,
                    render(h, { row }) {
                        let unit = _.get(row, 'needItemList.0.unit');
                        let txt = _.get(row, 'needItemList.0.productQuantity');
                        if (unit) {
                            txt += unit;
                        }
                        return h('span', txt);
                    },
                },

                {
                    title: '响应商家数量',
                    key: 'needIntentListListLen',
                    width: 130,
                    render(h, { row }) {
                        return h(
                            'span',
                            _.get(row, 'needIntentListList.length') || 0,
                        );
                    },
                },

                {
                    title: '状态',
                    key: 'status',
                    width: 140,
                    // fixed: 'right',
                    render(h, { row }) {
                        let needStatus =
                            $const.needStatus[_.get(row, 'status')] || {};

                        if (row.statusMsg) {
                            return h(
                                'span',
                                {
                                    style: {
                                        color: '#e54545',
                                    },
                                },
                                row.statusMsg,
                            );
                        }

                        return h(
                            'span',
                            {
                                style: {
                                    color: needStatus.color,
                                },
                            },
                            needStatus.display,
                        );
                    },
                },

                {
                    title: '操作',
                    key: 'action',
                    slot: 'action',
                    // fixed: 'right',
                    minWidth: 100,
                },
            ],
            data: [],
            pageOpts: {
                total: 0,
                size: 10,
                current: 1,
            },
        };
    },
    computed: {
        columnsRet() {
            if (!+this.tabType || +this.tabType === 56 ) {
                return this.columns;
            }
            return this.columns.filter((it) => it.key !== 'status');
        },
    },
    watch: {
        tabType: {
            handler() {
                this.getData();
            },
            immediate: true,
        },
    },
    methods: {
        getViewStyleText(row) {
            let status = +_.get(row, 'status');

            let m = {
                // 2: '查看',
                3: {
                    style: {
                        color: '#00c395',
                    },
                    text: '选择成交',
                },
                4: {
                    style: {
                        color: '#fea834',
                    },
                    text: '评价',
                },
            };

            if (row.statusMsg === '待选取商家') {
                return {
                    style: {
                        color: '#e54545',
                    },
                    text: '选择成交',
                };
            }

            if (m[status]) {
                return m[status];
            }

            return {
                text: '查看',
                style: {
                    color: '#e54545',
                },
            };
        },
        async getData(silent = false) {
            window.registerUpdateRunner = () => this.getData(); // 仅此刷新
            let formData = _.cloneDeep(this.formQuery || { daterange: [] });
            let [start, end] = formData.daterange || [];

            formData.startPublishTime = '';
            formData.endPublishTime = '';

            if (start) {
                formData.startPublishTime = dayjs(start).format(
                    'YYYY-MM-DD HH:mm:ss',
                );
                formData.endPublishTime = dayjs(end).format(
                    'YYYY-MM-DD HH:mm:ss',
                );
            }
            formData = _.omit(formData, 'daterange');

            let params = {
                pageSize: this.pageOpts.size,
                currentPage: this.pageOpts.current,
                ...formData,
            };
            if (+this.tabType) {
                params.status = this.tabType;
            }
            let res = await $service.need.pageByCondition(params, {silent});

            let data = _.get(res, 'data') || {};

            let total = _.get(data, 'total') || 0;
            // let current = _.get(data, 'current') || 0;
            // let size = _.get(data, 'size') || 0;
            let records = _.get(data, 'records') || [];
            // this.pageOpts = {total, size, current};
            this.pageOpts.total = total;
            this.data = records;
        },
        pageChange(current) {
            this.pageOpts.current = current;
            this.getData();
        },
        pageSizeChange(size) {
            this.pageOpts.size = size;
            this.getData();
        },
        // 操作
        handleView(row) {
            this.$router.push({
                path: '/home/buyer/view',
                query: {
                    id: row.id,
                },
            });
        },
    },
};
</script>
