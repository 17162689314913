<template>
    <div class="ic-bill-response">
        <div class="title mb24">
            <TagTitle title="抢单信息" />
        </div>

        <Divider class="divder" />

        <div class="mt20">
            <div class="flex-between flex-v-center mb10">
                <div class="flex-v-center pointer">
                    <div
                        v-for="it of sort.options"
                        :key="it.value"
                        class="mr20 flex-v-center"
                        :class="{ 'c-red': sort.type === it.value }"
                        @click="handleSort(it)"
                    >
                        <span>{{ it.display }}</span>
                        <a-icon
                            v-if="sort.type === it.value"
                            type="swap"
                            style="transform: rotate(90deg)"
                        />
                        <a-icon
                            v-else
                            type="swap"
                            style="transform: rotate(90deg)"
                        />
                    </div>
                </div>
                <div class="flex-v-center">
                    <div class="c-red mr20">
                        已有 {{ apiData.supplierCount }} 个响应
                    </div>
                    <div>
                        <Checkbox
                            v-model="agentLevel"
                            true-value="2"
                            false-value=""
                            >只看厂商/一代</Checkbox
                        >
                    </div>
                </div>
            </div>

            <div
                v-for="(it, idx) of data"
                :key="it.id"
                class="bg-gray pd20 mb10 relative"
            >
                <span v-if="idx < 5" class="absolute c-red recommend-icon"
                    >推荐</span
                >
                <div class="flex mb10 pointer" style="align-items: flex-end">
                    <span class="h4 mr10 c-black">{{ it.orgName }}</span>
                    <Tooltip>
                        <div slot="content">
                            <div>
                                商品的价格准确率: {{ it.priceAccurate * 100 }}%
                            </div>
                            <div>
                                商品的货期准确率:
                                {{ it.deliveryAccurate * 100 }}%
                            </div>
                        </div>
                        <span class="c-blue" style="margin-bottom: 2px"
                            >查看</span
                        >
                    </Tooltip>
                </div>

                <Row class="mb10 mt10">
                    <Col :span="6">
                        <span class="c-gray mr10">品牌:</span>
                        <span class="c-black">{{ it.brandName }}</span>
                    </Col>
                    <Col :span="6">
                        <span class="c-gray mr10">货号:</span>
                        <span class="c-black">{{ it.productNo }}</span>
                    </Col>
                    <Col :span="6">
                        <span class="c-gray mr10">现货类型:</span>
                        <span class="c-black">{{
                            it.stockType === 1 ? '国内现货' : '非国内现货'
                        }}</span>
                    </Col>
                    <Col :span="6">
                        <span class="c-gray mr10">预计到货日期:</span>
                        <span class="c-black" style="display: inline-flex">
                            {{ it.expectedReceiptTime }}天
                            <Tooltip
                                content="付款后到货天数(不含付款日)"
                                placement="top"
                            >
                                <a-icon
                                    type="question-circle"
                                    class="ml5"
                                    theme="twoTone"
                                />
                            </Tooltip>
                        </span>
                        <Tag
                            v-if="it.stockType === 1"
                            style="margin-left: 3px"
                            color="red"
                            >准</Tag
                        >
                    </Col>
                </Row>

                <Row class="mb10">
                    <Col :span="6">
                        <span class="c-gray mr10">单价:</span>
                        <span class="c-black">{{ it.price }} 元</span>
                    </Col>
                    <Col :span="6">
                        <span class="c-gray mr10">数量:</span>
                        <span class="c-black">{{ it.num }} {{ it.unit }}</span>
                    </Col>
                    <Col :span="6">
                        <span class="c-gray mr10">总价:</span>
                        <span class="c-black">{{ it.totalPrice }} 元</span>
                    </Col>
                    <Col :span="6">
                        <span class="c-gray mr10">资质</span>
                        <span>{{ getAgentLevel(it.agentLevel) }}</span>
                    </Col>
                </Row>
                <Button
                    v-if="
                        currentStatus === 3 ||
                        (currentStatus === 2 && data.length)
                    "
                    style="position: absolute; right: 20px; top: 20px"
                    type="success"
                    size="small"
                    @click="handleSelectConfirm(it)"
                >
                    选择成交
                </Button>
            </div>

            <div
                v-if="!data.length"
                class="c-black flex-v-center flex-center ht100"
            >
                暂无数据
            </div>
        </div>
    </div>
</template>

<script>
import needGetByIDMixin from '@/mixins/needGetByID.mixin';
import TagTitle from '@/components/TagTitle.vue';

export default {
    name: 'ViewInfo',
    components: { TagTitle },
    mixins: [needGetByIDMixin],
    data() {
        return {
            sort: {
                type: '1', // 1 综合排序, 2 价格优先, 3 货期优先
                options: [
                    {
                        value: '1',
                        display: '综合排序',
                    },
                    {
                        value: '2',
                        display: '价格优先',
                    },
                    {
                        value: '3',
                        display: '货期优先',
                    },
                ],
            },
            agentLevel: '', // 2 一代
            single: false,
            apiData: {},
            data: [],
        };
    },
    computed: {
        currentStatus() {
            return this.apiData.status;
        },
    },
    watch: {
        'sort.type'() {
            this.getData();
        },
        agentLevel() {
            this.getData();
        },
    },
    mounted() {
        if (this.$route.query.id) {
            this.getData();
        }
    },
    methods: {
        getAgentLevel(value) {
            return $util.getOptName(value, $const.options.agentLevel);
        },
        handleSort(it) {
            this.sort.type = it.value;
        },
        async getData(isUpdate = false, silent = false) {
            let id = this.$route.query.id;
            let params = {
                id,
                sortType: this.sort.type,
                // agentLevel: this.agentLevel
            };
            if (this.agentLevel) {
                params.agentLevel = this.agentLevel;
            }
            let res = await $service.need.getById(params, { silent });
            let data = _.get(res, 'data') || {};

            this.apiData = data;
            this.data = _.get(data, 'needIntentListList') || [];
            if (isUpdate) {
                await this.get$needIDData();
            }
        },
        handleSelectConfirm(it) {
            this.$Modal.confirm({
                title: '确认选择',
                content: `
                确认选择此商家交易，响应信息：
                <div>品牌：${it.brandName}</div>
                 <div>货号：${it.productNo}</div>
                 <div>单价：${it.price}元</div>
                 <div>总价：${it.totalPrice}元</div>
                 <div>数量：${it.num}${it.unit}</div>
                 <div>预计到货日期：${it.expectedReceiptTime}天</div>
                `,
                onOk: async () => {
                    await $service.need.selectSupplier({
                        id: this.apiData.id,
                        selectIntentList: [{ id: it.id }],
                    });

                    await this.getData(true);
                },
            });
        },
    },
};
</script>
<style lang="less" scoped>
@red: #de1e32;
.ic-bill-response {
    .prefix {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        padding: 2px;
    }

    .divder {
        margin: 0 10px 0px -24px;
        width: calc(100% + 45px);
    }

    .recommend-icon {
        border: 1px solid @red;
        top: 0;
        left: 0;
        background: rgba(229, 69, 69, 0.1);

        padding: 0 3px;
        font-size: 10px;
        font-weight: 500;
        color: #e54545;
        line-height: 14px;
    }
}
</style>
