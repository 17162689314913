<template>
    <div class="ic-bill-response">
        <div class="title mb24">
            <TagTitle title="成交信息" />
        </div>

        <Divider class="divder" />

        <div class="mt20">
            <div v-if="currentStatus === 4" class="c-red mb5">
                您选择成交的商家信息如下, 及时付款后商家将按约定货期为您供应产品
            </div>
            <div class="flex mb10" style="align-items: flex-end">
                <div class="bold c-black h4 mr10">{{ item.orgName }}</div>
                <div
                    class="c-red pointer"
                    @click="showCompanyInfo = { show: true, itemData: item }"
                >
                    查看公司详情
                </div>
            </div>
            <div>
                <Modal
                    v-model="showCompanyInfo.show"
                    title="公司详情"
                    :closable="false"
                    :footer-hide="true"
                    width="700"
                    height="600"
                >
                    <CompanyDetail
                        v-if="showCompanyInfo.show"
                        :item-data="showCompanyInfo.itemData"
                    />
                </Modal>
            </div>

            <div class="bg-gray mb10 radius4 pd20">
                <div class="bold c-black mb10">响应信息</div>

                <Row class="mb10">
                    <Col :span="6">
                        <div>
                            <span>品牌</span>
                            <span class="ml10 c-black">{{
                                item.brandName
                            }}</span>
                        </div>
                    </Col>
                    <Col :span="6">
                        <div>
                            <span>货号</span>
                            <span class="ml10 c-black">{{
                                item.productNo
                            }}</span>
                        </div>
                    </Col>
                    <Col :span="6">
                        <div>
                            <span>数量</span>
                            <span class="ml10 c-black">{{ item.num }}</span>
                        </div>
                    </Col>
                    <Col v-if="item.unit" :span="6">
                        <div>
                            <span>单位</span>
                            <span class="ml10 c-black">{{ item.unit }}</span>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col :span="6">
                        <div>
                            <span>单价</span>
                            <span class="ml10 c-black">{{ item.price }}元</span>
                        </div>
                    </Col>
                    <Col :span="6">
                        <div>
                            <span>总价</span>
                            <span class="ml10 c-black"
                                >{{ item.totalPrice }}元</span
                            >
                        </div>
                    </Col>
                    <Col :span="6">
                        <div class="flex-v-center">
                            <span>预期到货时间</span>
                            <span class="ml10 c-black">{{
                                item.expectedReceiptTime
                            }}</span
                            >天
                            <Tooltip
                                content="付款后到货天数(不含付款日)"
                                placement="top"
                            >
                                <a-icon
                                    type="question-circle"
                                    class="ml5"
                                    theme="twoTone"
                                />
                            </Tooltip>
                        </div>
                    </Col>
                </Row>
            </div>

            <div class="bg-gray mb10 radius4 pd20">
                <div class="bold c-black mb10">联系方式</div>
                <Row>
                    <Col :span="6">
                        <div>
                            <span>联系人</span>
                            <!--                            <span class="ml10 c-black">{{memberUsername}}</span>-->
                            <span class="ml10 c-black">{{ item.name }}</span>
                        </div>
                    </Col>
                    <Col :span="6">
                        <div>
                            <span>联系电话</span>
                            <span class="ml10 c-black">{{ item.phone }}</span>
                        </div>
                    </Col>
                    <Col :span="12">
                        <div>
                            <span>公司地址</span>
                            <span class="ml10 c-black">{{ address }}</span>
                        </div>
                    </Col>
                </Row>
            </div>

            <div class="bg-gray mb10 radius4 pd20">
                <div class="bold c-black mb10">服务评价</div>
                <div
                    class="flex-between relative"
                    style="align-items: flex-end"
                >
                    <div>
                        <Form
                            ref="formInline"
                            :label-width="100"
                            :model="formInline"
                            :rules="ruleInline"
                            inline
                        >
                            <Row>
                                <FormItem prop="priceFlag" label="价格是否准确">
                                    <RadioGroup v-model="formInline.priceFlag">
                                        <Radio
                                            :label="1"
                                            :disabled="currentStatus === 5"
                                        >
                                            <span>是</span>
                                        </Radio>
                                        <Radio
                                            :label="0"
                                            :disabled="currentStatus === 5"
                                        >
                                            <span>否</span>
                                        </Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Row>
                            <Row>
                                <FormItem
                                    prop="deliveryFlag"
                                    label="货期是否准确"
                                >
                                    <RadioGroup
                                        v-model="formInline.deliveryFlag"
                                    >
                                        <Radio
                                            :label="1"
                                            :disabled="currentStatus === 5"
                                        >
                                            <span>是</span>
                                        </Radio>
                                        <Radio
                                            :label="0"
                                            :disabled="currentStatus === 5"
                                        >
                                            <span>否</span>
                                        </Radio>
                                    </RadioGroup>
                                </FormItem>
                            </Row>
                        </Form>
                    </div>
                    <div class="absolute" style="right: 0; bottom: 20px">
                        <Button
                            v-if="currentStatus !== 5"
                            class="mr10"
                            type="primary"
                            @click="save"
                        >
                            提交评价
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import cityUtil from '@/util/cityUtil';
import currentOrgMixin from '@/mixins/currentOrg.mixin';
import needGetByIDMixin from '@/mixins/needGetByID.mixin';
import CompanyDetail from '@/pages/home/buyer/view/detail/CommentInfo/CompanyDetail.vue';
import TagTitle from '@/components/TagTitle.vue';

export default {
    name: 'CommentResponseInfo',
    components: { TagTitle, CompanyDetail },
    mixins: [currentOrgMixin, needGetByIDMixin],
    data() {
        return {
            isAccurate: false,
            showCompanyInfo: {
                show: false,
                itemData: null,
            }, // 公司详情
            formInline: {
                priceFlag: 1,
                deliveryFlag: 1,
            },
            ruleInline: {
                priceFlag: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (typeof value !== 'number') {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                deliveryFlag: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (typeof value !== 'number') {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
            },
            apiData: {},
            data: [],
        };
    },
    computed: {
        item() {
            let item = this.data[0] || {};
            return { ...item };
        },
        memberUsername() {
            return (
                _.get(this.item, 'org.member.username') || this.$emptyContent
            );
        },
        memberPhone() {
            return _.get(this.item, 'org.member.phone') || this.$emptyContent;
        },
        address() {
            let {
                province,
                city,
                region,
                detailAddress = '',
            } = _.get(this.apiData, 'org') || {};
            let provinceName =
                _.get(cityUtil.getItemByCode(province), 'text') || '';
            let cityName = _.get(cityUtil.getItemByCode(city), 'text') || '';
            let regionName =
                _.get(cityUtil.getItemByCode(region), 'text') || '';
            return `${provinceName}${cityName}${regionName}${detailAddress}`;
        },
        currentStatus() {
            return +this.apiData.status;
        },
    },
    mounted() {
        if (this.$route.query.id) {
            this.getData();
            this.getDataComment();
        }
    },
    methods: {
        async getData(isUpdate = false, silent = false) {
            let id = this.$route.query.id;
            let res = await $service.need.getByIdDetail({ id }, { silent });
            let data = _.get(res, 'data') || {};

            this.apiData = data;
            this.data = _.get(data, 'needIntentListList') || [];
            if (isUpdate) {
                await this.get$needIDData();
            }
        },
        async getDataComment() {
            let id = this.$route.query.id;
            let res = await $service.need.getByIdDetail({ id });
            let data = _.get(res, 'data') || {};
            this.data = _.get(data, 'needIntentListList') || [];
            this.formInline = _.get(data, 'comment') || {};
        },
        async save() {
            await new Promise((resolve) =>
                this.$refs.formInline.validate((valid) => valid && resolve()),
            );
            await $service.comment.create({
                orderId: this.$route.query.id,
                ...this.formInline,
            });

            await this.get$currentOrg();
            await this.getData(true);
        },
    },
};
</script>
<style lang="less" scoped>
@red: #de1e32;
.ic-bill-response {
    .prefix {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        padding: 2px;
    }

    .divder {
        margin: 0px 10px 0px -24px;
        width: calc(100% + 45px);
    }

    .recommend-icon {
        border: 1px solid @red;
        top: 0;
        left: 0;
        background: rgba(229, 69, 69, 0.1);

        padding: 0 3px;
        font-size: 10px;
        font-weight: 500;
        color: #e54545;
        line-height: 14px;
    }
}
</style>
