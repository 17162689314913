<template>
    <div v-if="$needIDData.id" class="mt20 mb20">
        <div class="pd24 bg-white mb20">
            <BillInfo />
        </div>
        <div class="pd24 bg-white mb20">
            <CommentInfo v-if="isCommentPage" ref="info" />
            <ViewInfo v-else ref="info" />
        </div>
    </div>
</template>

<script>
import BillInfo from './view/BillInfo.vue';
import ViewInfo from './view/detail/ViewInfo.vue';
import CommentInfo from './view/detail/CommentInfo.vue';
import needGetByIDMixin from '@/mixins/needGetByID.mixin';
import currentOrgMixin from '@/mixins/currentOrg.mixin';

export default {
    components: {
        BillInfo,
        ViewInfo,
        CommentInfo,
    },
    mixins: [needGetByIDMixin, currentOrgMixin],
    computed: {
        isCommentPage() {
            let status = +this.$needIDData.status;
            return status === 4 || status === 5; // 4,待评价 5, 已评价
        },
    },
    created() {
        this.get$needIDData();
    },
    mounted() {
        this.setTimer();
        this.get$currentOrg();
    },
    beforeDestroy() {
        this.clearTimer();
    },
    methods: {
        setTimer() {
            $util.timerUtil.setTimer(() => {
                try {
                    this.$refs.info.getData(false, true);
                } finally {
                    // ignore
                }
            });
        },
        clearTimer() {
            $util.timerUtil.clearTimer();
        },
    },
};
</script>
