<template>
    <div class="buyer-index">
        <div class="user-banner mb10">
            <img
                class="wp100 vertical-mid"
                src="../../../assets/img/banner/buyer.webp"
                alt=""
            />
        </div>
        <div class="buyer-index-tab flex-center m-auto mb10">
            <Tabs v-model="tabType">
                <TabPane
                    v-for="it of tabList"
                    :key="it.display"
                    :name="it.value + ''"
                    :label="it.display"
                />
            </Tabs>
        </div>
        <div class="buyer-index-search flex-center flex-v-center mb40">
            <Input
                v-model="formQuery.keywords"
                class="search-input"
                search
                enter-button="搜索"
                placeholder="请选择货号/品牌/名称关键字"
                clearable
                @on-search="onQuery"
            />
        </div>
        <BuyerTable ref="table" :form-query="formQuery" :tab-type="tabType" />
    </div>
</template>

<script>
import BuyerTable from './Index/BuyerTable';

export default {
    components: { BuyerTable },
    data() {
        return {
            formQuery: {
                type: '',
                keywords: '',
            },

            tabType: '',
            tabList: [
                { value: 0, display: '全部' },
                { ...$const.needStatus['1'] },
                { ...$const.needStatus['2'] },
                { ...$const.needStatus['3'] },
                { ...$const.needStatus['4'] },
                { ...$const.needStatus['56'] },
            ],
        };
    },
    mounted() {
        this.setTimer();
    },
    beforeDestroy() {
        this.clearTimer();
    },
    methods: {
        onQuery() {
            this.$refs.table.getData();
        },
        setTimer() {
            $util.timerUtil.setTimer(() => {
                this.$refs.table.getData(true);
            });
        },
        clearTimer() {
            $util.timerUtil.clearTimer();
        },
    },
};
</script>

<style lang="less" scoped>
@red: #de1e32;
.buyer-index {
    /deep/ &-tab {
        .ivu-tabs-bar {
            border: none;

            .ivu-tabs-tab {
                &:hover {
                    color: @red;
                }

                &-active,
                &-focused {
                    color: @red;
                }
            }

            .ivu-tabs-ink-bar {
                background-color: @red;
            }
        }
    }
    /deep/ &-search {
        .search-type {
            .ivu-select-selection {
                border: 1px solid @red;
                .ivu-select-placeholder {
                    color: @red;
                }

                .ivu-select-arrow {
                    color: @red;
                }

                .ivu-select-selected-value {
                    color: @red;
                }
            }
        }
        .search-input {
            margin-left: 10px;
            width: 500px;
            border: none;
            outline: none;
            line-height: 30px;

            .ivu-input {
                border: 1px solid @red;
            }
        }
    }
}
</style>
