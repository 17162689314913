<template>
    <div class="bid-item-view">
        <div class="flex-between mb20">
            <TagTitle title="需求信息" />

            <div class="flex-v-center">
                <Button type="text" icon="arrow-left-c" @click="handleHome">
                    返回
                </Button>
            </div>
        </div>

        <DemandInfo :api-data="apiData" />
        <DemandInfoContact :api-data="apiData" />
    </div>
</template>

<script>
// 询货内容
import TagTitle from '@/components/TagTitle.vue';
import DemandInfo from '@/pages/home/seller/share/DemandInfo.vue';
import DemandInfoContact from '@/pages/home/seller/share/DemandInfoContact.vue';

export default {
    components: { DemandInfoContact, DemandInfo, TagTitle },
    props: {
        apiData: {},
    },
    methods: {
        handleHome() {
            if (this.$route.path === '/home/seller/bid') {
                this.$router.push('/home/seller/bidIndex');
                return;
            }
            this.$router.push('/home/seller/index');
        },
    },
};
</script>
