<template>
    <div>
        <Button
            v-if="!disabled && row.extensionIndex === 0"
            class="mr10"
            type="primary"
            @click="handleExtension(row)"
        >
            延时
        </Button>

        <!-- 延时弹窗 -->
        <Modal
            v-model="extensionModel"
            title="延长抢单时间"
            :footer-hide="true"
        >
            <Form
                ref="extension"
                :label-width="160"
                :model="extension"
                :rules="extensionRule"
                inline
            >
                <FormItem
                    prop="extensionType"
                    label="希望完成询货的时间"
                    :required="true"
                    placeholder="请选择延长时间"
                >
                    <Select
                        v-model="extension.extensionType"
                        class="w200"
                        placeholder="请选择延长时间"
                    >
                        <Option
                            v-for="it of options.extensionType"
                            :key="it.text"
                            :value="it.value"
                        >
                            {{ it.text }}
                        </Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="extensionSubmit"
                        >提交</Button
                    >
                    <Button
                        style="margin-left: 8px"
                        @click="extensionModel = false"
                        >取消</Button
                    >
                </FormItem>
            </Form>
        </Modal>
    </div>
</template>
<script>
import needGetByIDMixin from '@/mixins/needGetByID.mixin';

export default {
    mixins: [needGetByIDMixin],
    props: {
        row: {},
    },
    data() {
        return {
            extensionModel: false,
            extension: {
                extensionType: '',
                needId: '',
            },
            options: {
                extensionType: [...$const.options.extensionType],
            },
            extensionRule: {
                extensionType: [
                    {
                        required: true,
                        message: '请选择延长时间',
                        trigger: 'change',
                    },
                ],
            },
        };
    },
    computed: {
        disabled() {
            let row = this.row;
            return +row.status !== 2;
        },
    },
    methods: {
        getData() {
            this.get$needIDData();
        },
        async extensionSubmit() {
            await new Promise((resolve) =>
                this.$refs.extension.validate((valid) => valid && resolve()),
            );
            let res = await $service.need.extension({
                id: this.extension.needId,
                expireTimeType: this.extension.extensionType,
            });
            if (res.code !== 100000) {
                this.$Message.info(res.message);
                return;
            }
            this.$Message.info('操作成功');
            this.extensionModel = false;
            await this.getData();
        },
        handleExtension(row) {
            this.extension.needId = row.id;
            this.extensionModel = true;
        },
    },
};
</script>
