<template>
    <div>
        <Button
            v-if="!disabled"
            class="mr10"
            type="primary"
            @click="handleCheckCancel(row)"
        >
            取消
        </Button>
    </div>
</template>
<script>
import needGetByIDMixin from '@/mixins/needGetByID.mixin';

export default {
    mixins: [needGetByIDMixin],
    props: {
        row: {},
    },
    data() {
        return {
            extensionModel: false,
            extension: {
                extensionType: '',
                needId: '',
            },
            options: {
                extensionType: [...$const.options.extensionType],
            },
            extensionRule: {
                extensionType: [
                    {
                        required: true,
                        message: '请选择延长时间',
                        trigger: 'change',
                    },
                ],
            },
        };
    },
    computed: {
        disabled() {
            let row = this.row;
            return +row.status >= 4;
        },
    },
    methods: {
        getData() {
            this.get$needIDData();
        },
        async handleCheckCancel({ id }) {
            let data = await $service.need.checkCancel({ id });
            let cont = '';
            if (data.data) {
                cont = '是否确认取消本单？';
            } else {
                cont =
                    '是否确认取消本单？本单已有商家响应，您的操作会影响您的的买家身份评分？';
            }
            this.$Modal.confirm({
                title: '取消',
                content: cont,
                onOk: async () => {
                    await $service.need.cancel({ id });
                    console.log(
                        '==================================================',
                    );
                    console.log(data);
                    // } else {
                    //     console.log("=====")
                    //     this.$Modal.confirm({
                    //         title: '有商家已经响应，您的操作会影响您的的买家身份评分',
                    //         content: '确认',
                    //         onOk: async () => {
                    //             await $service.need.cancel({id});
                    //             await this.getData();
                    //         }
                    //     });
                    await this.getData();
                },
            });
        },
    },
};
</script>
