<template>
    <div class="company-detail">
        <ICard class="icard-overrider" order="1" title="企业基础信息">
            <Form ref="formValidate" :label-width="120">
                <FormItem label="名称:">
                    <div>
                        {{ name }}
                    </div>
                </FormItem>
                <FormItem label="所在城市:">
                    <div>
                        {{ addrText }}
                    </div>
                </FormItem>
                <FormItem label="营业执照:">
                    <div v-if="businessLicense">
                        <ViewImg :src="businessLicense" />
                    </div>
                </FormItem>
            </Form>
        </ICard>
        <ICard order="2" title="品牌资质" class="icard-overrider">
            <Form ref="formValidate" :label-width="120">
                <FormItem label="品牌:">
                    <div>
                        {{ brandName }}
                    </div>
                </FormItem>
                <FormItem label="资质:">
                    <div>
                        {{ qualification }}
                    </div>
                </FormItem>
                <FormItem label="资质照片:">
                    <div v-if="picUrl">
                        <ViewImg :src="picUrl" />
                    </div>
                </FormItem>
            </Form>
        </ICard>
        <ICard order="3" title="票款信息" class="icard-overrider">
            <Form ref="formValidate" :label-width="120">
                <FormItem label="名称:">
                    <div>
                        {{ invoiceName }}
                    </div>
                </FormItem>
                <FormItem label="纳税人识别号:">
                    <div>
                        {{ invoiceSn }}
                    </div>
                </FormItem>
                <FormItem label="地址:">
                    <div>
                        {{ invoiceAddr }}
                    </div>
                </FormItem>
                <FormItem label="电话:">
                    <div>
                        {{ invoicePhone }}
                    </div>
                </FormItem>
                <FormItem label="开户行:">
                    <div>
                        {{ invoiceBank }}
                    </div>
                </FormItem>
                <FormItem label="账号:">
                    <div>
                        {{ invoiceAccount }}
                    </div>
                </FormItem>
            </Form>
        </ICard>
        <ICard order="4" title="评价信息" class="icard-overrider">
            <Form ref="formValidate" :label-width="120">
                <FormItem label="价格准确率:">
                    <div>{{ countInfo.priceAccurate * 100 }}%</div>
                </FormItem>
                <FormItem label="货期准确率:">
                    <div>{{ countInfo.deliveryAccurate * 100 }}%</div>
                </FormItem>
            </Form>
        </ICard>
    </div>
</template>
<script>
import currentOrgMixin from '@/mixins/currentOrg.mixin';
import ICard from '@/components/ICard.vue';
import cityUtil from '@/util/cityUtil';
import ViewImg from '@/components/ViewImg.vue';
import needGetByIDMixin from '@/mixins/needGetByID.mixin';

export default {
    components: { ViewImg, ICard },
    mixins: [currentOrgMixin, needGetByIDMixin],
    props: {
        // 这个是传递过来的投标信息
        itemData: {},
    },
    data() {
        return {
            countInfo: {
                resCount: '',
                bidSuccessCount: '',
                totalAmount: '',
                priceAccurate: '',
                deliveryAccurate: '',
                orgBrandList: [],
            },
            intentByNeedId: {},
            orgData: {},
        };
    },
    computed: {
        // orgData() {
        //     console.log('company detail item data =====', this.itemData);
        //     if (this.itemData) {
        //         let res = $service.org.getCurrentOrg({ id: this.itemData.orgId });
        //         return _.get(res, 'org') || {};
        //     }
        //     return _.get(this.$currentOrg, 'org') || {};
        // },
        name() {
            console.log('getData org name ====', this.orgData);
            return _.get(this.orgData, 'name') || this.$emptyContent;
        },
        businessLicense() {
            return _.get(this.orgData, 'businessLicense');
        },
        picUrl() {
            let brandId = _.get(
                this.intentByNeedId,
                'needIntentListList[0].brandId',
            );
            let orgBrandList = _.get(this.countInfo, 'orgBrandList') || [];
            let item = orgBrandList.find((it) => brandId === it.brandId);
            return _.get(item, 'picUrl');
        },
        qualification() {
            // let q = _.get(this.orgData, 'qualification');
            // 获取对应的品牌信息
            let orgBrandList = _.get(this.countInfo, 'orgBrandList') || [];
            let brandId;
            let item = orgBrandList.find((it) => brandId === it.brandId);
            let q = _.get(item, 'qualification');
            if(q){
                return _.get($const.qualification[q], 'display');
            }else {
                return _.get($const.qualification[3], 'display');
            }
        },
        addrText() {
            let { province, city, region } = this.orgData;
            if (!province || !city || !region) {
                return '-';
            }

            let provinceText = _.get(cityUtil.getItemByCode(province), 'text');
            let cityText = _.get(cityUtil.getItemByCode(city), 'text');
            let regionText = _.get(cityUtil.getItemByCode(region), 'text');

            if (provinceText === cityText) {
                return [provinceText, regionText].join('/');
            }

            return [provinceText, cityText, regionText].join('/');
        },
        invoiceName() {
            return _.get(this.orgData, 'invoiceName');
        },
        invoiceSn() {
            return _.get(this.orgData, 'invoiceSn');
        },
        invoiceAddr() {
            return _.get(this.orgData, 'invoiceAddr');
        },
        invoicePhone() {
            return _.get(this.orgData, 'invoicePhone');
        },
        invoiceBank() {
            return _.get(this.orgData, 'invoiceBank');
        },
        invoiceAccount() {
            return _.get(this.orgData, 'invoiceAccount');
        },
        brandName() {
            return (
                _.get(this.$needIDData, 'needIntentListList[0].brandName') ||
                this.$emptyContent
            );
        },
    },
    created() {
        this.getData();
        this.getIntentByNeedId();

    },
    methods: {
        async getData() {
            console.log(this.itemData, '====itemData');
            //查询品牌
            let res = await $service.org.getSellerInfo({
                id: this.itemData.orgId,
            });
            this.countInfo = res.data;
            //查询卖家
            if (this.itemData) {
                let res2 = await $service.org.getCurrentOrg({ id: this.itemData.orgId });
                this.orgData = _.get(res2.data, 'org') || {};
            }else {
                this.orgData = _.get(this.$currentOrg, 'org') || {};
            }


            console.log('getData org ====', this.orgData);
        },
        async getIntentByNeedId() {
            let id = this.$route.query.id;
            let res = await $service.needIntent.getIntentByNeedId({
                needId: id,
            });
            this.intentByNeedId = _.get(res, 'data') || {};
        },
    },
};
</script>

<style lang="less" scoped>
.company-detail {
    height: 70vh;
    overflow-y: scroll;
    padding: 10px;

    .icard-overrider {
        padding: 0;
        padding-top: 20px;
    }

    .ivu-form-item {
        margin-bottom: 0;
    }
}
</style>
