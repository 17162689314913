<template>
    <div>
        <Button
            v-if="!disabled"
            class="mr10"
            type="primary"
            @click="handlePub(row)"
        >
            发布
        </Button>
    </div>
</template>
<script>
import needGetByIDMixin from '@/mixins/needGetByID.mixin';

export default {
    mixins: [needGetByIDMixin],
    props: {
        row: {},
    },
    computed: {
        disabled() {
            let row = this.row;
            return +row.status !== 1;
        },
    },
    methods: {
        getData() {
            this.get$needIDData();
        },
        async handlePub(row) {
            await $service.need.publish(row);
            await this.getData();
        },
    },
};
</script>
