<template>
    <div class="company-detail">
        <ICard class="icard-overrider" order="1" title="企业基础信息">
            <Form ref="formValidate" :label-width="120">
                <FormItem label="名称:">
                    <div>
                        {{ name }}
                    </div>
                </FormItem>
                <FormItem label="所在城市:">
                    <div>
                        {{ addrText }}
                    </div>
                </FormItem>
            </Form>
        </ICard>

        <ICard order="2" title="票款信息" class="icard-overrider">
            <Form ref="formValidate" :label-width="120">
                <FormItem label="名称">
                    <span>{{ orgData.invoiceName }}</span>
                </FormItem>
                <FormItem label="纳税人识别号">
                    <span>{{ orgData.invoiceSn }}</span>
                </FormItem>
                <FormItem label="地址">
                    <span>{{ orgData.invoiceAddr }}</span>
                </FormItem>
                <FormItem label="电话">
                    <span>{{ orgData.invoicePhone }}</span>
                </FormItem>
                <FormItem label="开户行">
                    <span>{{ orgData.invoiceBank }}</span>
                </FormItem>
                <FormItem label="账号">
                    <span>{{ orgData.invoiceAccount }}</span>
                </FormItem>
            </Form>
        </ICard>
    </div>
</template>
<script>
import currentOrgMixin from '@/mixins/currentOrg.mixin';
import ICard from '@/components/ICard.vue';
import cityUtil from '@/util/cityUtil';
import ViewImg from '@/components/ViewImg.vue';

export default {
    components: { ViewImg, ICard },
    mixins: [currentOrgMixin],
    props: {
        itemData: {},
    },
    data() {
        return {
            countInfo: {
                resCount: '',
                bidSuccessCount: '',
                totalAmount: '',
                priceAccurate: '',
                deliveryAccurate: '',
                performanceAccurate: '',
            },
        };
    },
    computed: {
        orgData() {
            return _.get(this.$currentOrg, 'org') || {};
        },
        name() {
            return _.get(this.orgData, 'name') || this.$emptyContent;
        },
        addrText() {
            let { province, city, region } = this.orgData;
            if (!province || !city || !region) {
                return '-';
            }

            let provinceText = _.get(cityUtil.getItemByCode(province), 'text');
            let cityText = _.get(cityUtil.getItemByCode(city), 'text');
            let regionText = _.get(cityUtil.getItemByCode(region), 'text');

            if (provinceText === cityText) {
                return [provinceText, regionText].join('/');
            }

            return [provinceText, cityText, regionText].join('/');
        },
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            console.log(this.itemData, 'itemData.detail...');
            //查询品牌
            let res = await $service.org.getSellerInfo({
                id: this.itemData.orgId,
            });
            this.countInfo = res.data;
        },
    },
};
</script>

<style lang="less" scoped>
.company-detail {
    padding: 10px;

    .icard-overrider {
        padding: 0;
        padding-top: 20px;
    }

    .ivu-form-item {
        margin-bottom: 0;
    }
}
</style>
