<template>
    <div v-if="showContent">
        <TagTitle title="收货信息" :is-light="true" />
        <div class="bg-gray pdt20 mt10 pdb5">
            <IReadCol :label-width="140" :cols="1" :items="readColItems" />
        </div>
    </div>
</template>
<script>
import TagTitle from '@/components/TagTitle.vue';
import IReadCol from '@/components/ui/IReadCol.vue';

export default {
    components: { IReadCol, TagTitle },
    props: {
        apiData: {},
    },
    computed: {
        readColItems() {
            return [
                {
                    title: '联系人:',
                    content: this.contactName,
                },
                {
                    title: '联系电话:',
                    content: this.contactPhone,
                },
                {
                    title: '发货地址:',
                    content: this.receiverDetailAddress,
                },
            ];
        },
        contactName() {
            return this.apiData.contactName;
        },
        contactPhone() {
            return this.apiData.contactPhone;
        },
        receiverDetailAddress() {
            return this.apiData.receiverDetailAddress;
        },
        showContent() {
            return (
                this.contactName &&
                this.contactPhone &&
                this.receiverDetailAddress
            );
        },
    },
};
</script>
