<template>
    <div class="seller-response-table">
        <TagTitle title="抢单信息">
            <div slot="righter">
                <span>抢单状态: </span>
                <span :style="{color: status.color}">{{ status.display }}</span>
            </div>
        </TagTitle>
        <div class="seller-response-table-wrap pdt20 relative">
            <IReadCol
                :source="item"
                :items="bidInfoItems"
            />
            <div v-if="item.status===5 && !backLog.length" class="flex-v-center absolute" style="top: 60px; right: 0;">
                <span style="border: 1px solid rgb(229, 69, 69); padding: 2px;" class="c-red pointer" @click="handleLvYue">未履约反馈</span>
                <Tooltip placement="left" content="如果买方机构选中您后最终没有选择和您进行交易，可以提交反馈，如果平台确认是买方非正常行为，平台会进行退费处理。">
                    <a-icon type="question-circle" class="ml5" theme="twoTone"/>
                </Tooltip>
            </div>
            <div class="mb10" v-if="item.contactName && item.contactPhone">
                <IReadCol
                    :source="item"
                    :items="contactInfoItems"
                />
            </div>
        </div>
        <div>
            <TagTitle title="联系方式"/>
            <div class="bg-gray pdt20 mt10 " style="padding-bottom: 5px;">
                <IReadCol
                    :source="item"
                    :items="contact2InfoItems"
                />
            </div>
        </div>
        <div v-if="backLog.length" class="mt10">
            <TagTitle title="反馈动态"/>
            <div class="bg-gray pdt20 mt10 " style="padding: 20px;">
               <div v-for="(it,idx) of backLog" :key="idx" class="flex-v-center flex-between mb10">
                   <div>
                       <a-icon class="mr5" type="user" />
                       <span style="white-space: nowrap;" class="mr10" v-if="idx===0">商家反馈:</span>
                       <span style="white-space: nowrap;" class="mr10" v-else>平台反馈:</span>
                       <span>{{it.msg}}</span>
                   </div>
                   <div>
                       {{it.createTimeStr}}
                   </div>
               </div>
            </div>
        </div>

        <Modal
            v-model="lvYue.show"
            title="履约"
            @on-cancel="lvYue.show=false"
            footer-hide
        >
           <div>
               <Input
                   v-model="lvYue.content"
                   type="textarea"
                   :rows="4"
                   placeholder="请输入反馈的问题"
                   :maxlength="100"
               />
           </div>
            <div class="flex-end flex-v-center mt10">
                <Button @click="lvYue.show=false">取消</Button>
                <Button type="primary" class="ml10" @click="handleLvYueConfirm">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import TagTitle from "@/components/TagTitle.vue";
import IReadCol from "@/components/ui/IReadCol.vue";


export default {
    components: {IReadCol, TagTitle},
    props: {
        apiData: {}
    },
    data() {
        return {
            lvYue: {
                show: false,
                content: ''
            },
        };
    },
    computed: {
        status() {
            let e = {display: this.$emptyContent};
            let row = this.item;
            if (row.intentStatus) {
                return $const.needIntentStatus[_.get(row, 'intentStatus')] || e;

            }
            return $const.needIntentStatus[_.get(row, 'status')] || e;
        },
        bidInfoItems() {
            return [
                {
                    title: '品牌:',
                    path: 'brandName'
                },
                {
                    title: '货号:',
                    path: 'productNo'
                },
                {hide: true},
                {
                    title: '单价:',
                    path: 'price',
                    formatter: (text) => `${text}元`
                },
                {
                    title: '数量:',
                    path: 'num'
                },
                {
                    title: '总价:',
                    path: 'totalPrice',
                    formatter: (text) => `${text}元`
                },
                {
                    title: '预计到货日期:',
                    path: 'expectedReceiptTime',
                    render: () => {
                        return (
                            <div class="flex-v-center">
                                <span class="mr5">{this.item.expectedReceiptTime}天</span>
                                <Tooltip content="付款后到货天数(不含付款日)" placement="top">
                                    <a-icon type="question-circle" className="ml5" theme="twoTone"/>
                                </Tooltip>
                            </div>
                        )
                    }
                },
            ]
        },
        contactInfoItems() {
            return [
                {
                    title: '联系人:',
                    path: 'contactName'
                },
                {
                    title: '联系电话:',
                    path: 'contactPhone'
                },
            ]
        },
        contact2InfoItems() {
            return [
                {
                    title: '联系人:',
                    path: 'name'
                },
                {
                    title: '联系电话:',
                    path: 'phone'
                },
            ]
        },
        item() {
            let data = _.get(this.apiData, 'needIntentListList') || [];
            return data[0] || {};
        },
        backLog() {
            return _.get(this.apiData, 'backLog') || [];
        }
    },
    mounted() {
        window.seller = this;
    },
    methods: {
        handleLvYue() {
            this.lvYue.show = true;
            this.lvYue.content = '';
        },
        async handleLvYueConfirm() {
            let content = (this.lvYue.content || '').trim();
            if (!content) {
                this.$toast.warning('提示', '请输入反馈问题');
                return;
            }

            console.log('内容', content, this.item.id, this.item);
            let res = await $service.needFeedback.needFeedbackAdd({
                qaMsg: content,
                needId: this.item.needId
            });
            console.log(res, 'res');
            this.lvYue.show = false;
            this.$emit('getData');
        },
    },
};
</script>
