<style scoped lang="less">
.view-detail-button {
    line-height: 20px;
    border: 1px solid currentColor;
    padding: 2px 4px;
}
</style>

<template>
    <div>
        <div>
            <Modal
                v-model="showDetail.show"
                title="详情"
                :closable="false"
                :footer-hide="true"
                width="700"
                height="600"
            >
                <Detail v-if="showDetail.show" :itemData="showDetail.itemData"/>
            </Modal>
        </div>
        <IReadCol :source="apiData" :items="readColItems"/>
    </div>
</template>
<script>
import ViewImg from "@/components/ViewImg.vue";
import Detail from "@/pages/home/seller/share/DemandInfo/Detail.vue";
import IReadCol from "@/components/ui/IReadCol.vue";
import Tooltip from "view-design/src/components/tooltip";
import currentOrgMixin from "@/mixins/currentOrg.mixin";


export default {
    mixins: [currentOrgMixin],
    components: {IReadCol, Detail, ViewImg},
    props: {
        apiData: {}
    },
    data () {
        window._demandInfo = this;
        return {
            showDetail: {
                show: false,
                itemData: null,
            },
        }
    },
    computed: {
        readColItems() {
            let isKeYanJigou = +_.get(this.apiData, 'orgQualificationId') === 1;
            console.log(this.item, 'item...');

            let s = {
                1: {
                    title: '货号:',
                    render: () => {
                        let productNo = _.get(this.apiData, 'needItemList[0].productNo');
                        if (this.orderType === 1) {
                            return productNo;
                        }
                        return this.$emptyContent;
                    }
                },
                2: {
                    title: '名称:',
                    render: () => {
                        let productName = _.get(this.apiData, 'needItemList[0].productName');
                        if (this.orderType === 2) {
                            return productName;
                        }
                        return this.$emptyContent;
                    }
                },
                3: {
                    title: '图片:',
                    render: () => {
                        let productPic = _.get(this.apiData, 'needItemList[0].productPic');
                        if (this.orderType === 3 && productPic) {
                            return (
                                <ViewImg
                                    src={productPic}
                                    alt={productPic}
                                />
                            );
                        }
                        return this.$emptyContent;
                    }
                }
            };

            let lvyuelv = {
                title: '买家履约率:',
                render: () => {

                    return (
                        <div class="flex-v-center">
                            100%
                            <Tooltip
                                content="已完成线下交易的需求数与已发布的有效总需求数的占比。其中，有效总需求数不包含0响应的需求"
                                placement="top"
                            >
                                <a-icon type="question-circle" class="ml5" theme="twoTone"/>
                            </Tooltip>
                        </div>
                    );
                },
            };

            let colItem = s[this.orderType] || {hide: true};
            let isComplete = this.item.status >= 5;
            let maijiaNameBefore = isComplete ? [{hide: true}] : [{hide: true}];
            let lvyuelvCol = isComplete ? [] : [lvyuelv];

            let intentStatus = _.get(this.apiData, 'needIntentListList[0].status');

            let maijiaDisplayHide = !_.get(this.apiData, 'needIntentListList[0].orgName') || intentStatus < 5;

            let maijname = {
                title: '买家名称:',
                // 不存在orgName 或者没成交的时候隐藏 status < 5
                // hide: !_.get(this.apiData, 'needIntentListList[0].orgName') || intentStatus < 5,
                render: () => {
                    let orgName = _.get(this.apiData, 'needIntentListList[0].orgName');
                    if (!orgName) {
                        return this.$emptyContent;
                    }

                    return (
                        <div class="flex-v-center">
                            <Tooltip content={orgName}>
                                <div class="ellipse" style={{maxWidth: '100px', marginTop: '5px'}}>
                                    {orgName}
                                </div>
                            </Tooltip>

                            <div style={{whiteSpace: 'nowrap'}} class="ellipse c-red ml5 pointer view-detail-button" onClick={this.viewDetail}>
                                查看详情
                            </div>
                        </div>
                    );
                },
            }

            return [
                {
                    title: '需求编号:',
                    path: 'needSn'
                },
                {
                    title: '发布时间:',
                    path: 'publishTime',
                    formatter: $util.format$Date,
                },
                ...maijiaNameBefore,
                ...(maijiaDisplayHide ? [] : [maijname]),
                {
                    title: '买家类型:',
                    path: 'orgQualification'
                },
                {
                    title: '买家区域:',
                    path: 'receiverTerminalAddress'
                },
                ...lvyuelvCol,
                {
                    title: '品牌:',
                    render: () => {
                        let productBrand = _.get(this.apiData, 'needItemList[0].productBrand');
                        if (this.orderType === 1 || this.orderType === 3) {
                            return productBrand;
                        }
                        return this.$emptyContent;
                    }
                },
                colItem,
                {
                    title: '采购数量:',
                    render: () => {
                        let productQuantity = _.get(this.apiData, 'needItemList[0].productQuantity');
                        let unit = _.get(this.apiData, 'needItemList[0].unit');
                        let specs = _.get(this.apiData, 'needItemList[0].specs');
                        return (
                            <div class="flex-v-center">
                                <div>{productQuantity}</div>
                                {unit && <div>{unit}</div>}
                                {specs && <div>{specs}</div>}
                            </div>
                        );
                    }
                },
                {
                    title: '商品类型:',
                    path: 'needItemList.0.productCategoryType',
                    select: {
                        options: $const.options.productCategoryType,
                    }
                },
                {
                    title: '采购优先项:',
                    path: 'priorityType',
                    select: {
                        options: $const.options.priorityType,
                    }
                },
                {
                    title: '需走订单流程平台:',
                    path: 'purchasePlatform',
                    hide: !isKeYanJigou, // 科研机构展示
                    select: {
                        options: $const.options.purchasePlatform,
                    }
                }
            ];
        },
        item() {
            let data = _.get(this.apiData, 'needIntentListList') || [];
            return data[0] || {};
        },
        orderType() {
            let data = this.apiData;
            return +_.get(data, 'orderType')
        },
    },
    methods: {
        viewDetail() {
            this.showDetail = {
                show: true,
                itemData: this.apiData,
            };
        }
    }
}
</script>
