<template>
    <div class="mt20 mb20 bg-white">
        <div class="pd24 bg-white">
            <div v-if="sellerStatus === 5" class="c-red">
                ！恭喜您中标，收到买家付款后，请按约定货期为买家完成供应。
            </div>
            <DemandViewer :api-data="apiData" />
        </div>
        <div class="pd24 bg-white mb20" style="border-top: 10px solid #f5f7f9">
            <SellerResponse :api-data="apiData" @getData="getData" />
        </div>
    </div>
</template>

<script>
import SellerResponse from './View/SellerResponse';
import DemandViewer from '@/pages/home/seller/View/DemandViewer.vue';

export default {
    components: {
        DemandViewer,
        SellerResponse,
    },
    data() {
        window.rapiData = this;
        return {
            apiData: {},
        };
    },
    computed: {
        sellerStatus() {
            return _.get(this.apiData, 'needIntentListList.0.status');
        },
    },
    created() {
        this.getData();
    },
    mounted() {
        this.setTimer();
    },
    beforeDestroy() {
        this.clearTimer();
    },
    methods: {
        setTimer() {
            $util.timerUtil.setTimer(async () => {
                try {
                    await this.getData(true);
                } finally {
                    // 结束状态取消定时
                    if (this.apiData.status >= 5) {
                        this.clearTimer();
                    }
                }
            });
        },
        clearTimer() {
            $util.timerUtil.clearTimer();
        },
        async getData(silent = false) {
            let id = this.$route.query.id;
            let res = await $service.needIntent.getIntentByNeedId(
                {
                    needId: id,
                },
                { silent },
            );

            this.apiData = _.get(res, 'data') || {};
        },
    },
};
</script>
