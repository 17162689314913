<template>
    <div class="buyer-bill-wrapper bg-white pd24">
        <div class="title mb10">
            <TagTitle title="询货方式" />
        </div>
        <RadioGroup v-model="orderType" class="mb24">
            <Radio label="1">
                <div class="inline-block">
                    货号发单
                    <img
                        height="16"
                        src="../../../../assets/img/icon/recommend.png"
                        alt=""
                    />
                </div>
            </Radio>
            <Radio label="3">
                <div class="inline-block">
                    图片发单
                    <img
                        height="16"
                        src="../../../../assets/img/icon/recommend.png"
                        alt=""
                    />
                </div>
            </Radio>
            <Radio v-if="isTypeShow" label="2">
                <div class="inline-block">名称发单</div>
            </Radio>
        </RadioGroup>

        <div class="title mb24">
            <TagTitle title="询货内容" />
        </div>
        <Form
            ref="formInline"
            :model="formInline"
            :rules="ruleInline"
            label-position="top"
        >
            <Row>
                <Col v-if="+orderType === 1 || +orderType === 3" :span="12">
                    <FormItem prop="brandId" label="品牌" class="pdr20">
                        <Select
                            v-model="formInline.brandId"
                            class="wp100"
                            placeholder="品牌"
                            filterable
                            remote
                            :remote-method="remoteMethod"
                        >
                            <Option
                                v-for="it of options.brandId2"
                                :key="it.value"
                                :value="it.value"
                            >
                                {{ it.text }}
                            </Option>
                        </Select>
                    </FormItem>
                </Col>
                <Col v-if="+orderType === 1" :span="12">
                    <FormItem prop="productNo" label="货号" class="pdr20">
                        <Input
                            :maxlength="20"
                            v-model="formInline.productNo"
                            class="wp100"
                            type="text"
                            placeholder="货号"
                            clearable
                        />
                    </FormItem>
                </Col>
                <Col v-if="+orderType === 2" :span="12">
                    <FormItem prop="productName" label="名称" class="pdr20">
                        <Input
                            v-model="formInline.productName"
                            class="wp100"
                            type="text"
                            placeholder="名称"
                            clearable
                        />
                    </FormItem>
                </Col>
                <Col v-if="+orderType === 3" :span="12">
                    <FormItem prop="productPic" label="图片">
                        <IUpload v-model="formInline.productPic" />
                    </FormItem>
                </Col>
            </Row>

            <Row>
                <Col :span="12">
                    <FormItem
                        prop="productCategoryType"
                        label="商品类型"
                        class="pdr20"
                    >
                        <Select
                            v-model="formInline.productCategoryType"
                            class="wp100"
                            placeholder="商品类型"
                        >
                            <Option
                                v-for="it of options.productCategoryType"
                                :key="it.text"
                                :value="it.value"
                            >
                                {{ it.text }}
                            </Option>
                        </Select>
                    </FormItem>
                </Col>
                <Col :span="8">
                    <FormItem
                        prop="productQuantity"
                        label="采购数量"
                        class="pdr20"
                    >
                        <InputNumber
                            v-model="formInline.productQuantity"
                            class="wp100"
                            :min="1"
                            :step="1"
                            :precision="0"
                            placeholder="采购数量"
                            clearable
                        />
                    </FormItem>
                </Col>
                <Col v-if="+formInline.productCategoryType === 2" :span="4">
                    <FormItem
                        prop="unit"
                        label="采购单位"
                        class="pdr20"
                        style="margin-top: 2px"
                    >
                        <Select
                            v-model="formInline.unit"
                            class="wp100"
                            placeholder="采购单位"
                        >
                            <Option
                                v-for="it of options.unitType"
                                :key="it.text"
                                :value="it.value"
                            >
                                {{ it.text }}
                            </Option>
                        </Select>
                    </FormItem>
                </Col>
                <Col v-if="+formInline.productCategoryType === 1" :span="4">
                    <FormItem
                        label="商品规格"
                        class="pdr20"
                        style="margin-top: 2px"
                    >
                        <Input
                            v-model="formInline.specs"
                            class="wp100"
                            type="text"
                            placeholder="商品规格"
                            clearable
                        />
                    </FormItem>
                </Col>
            </Row>

            <Row>
                <Col :span="12">
                    <FormItem
                        prop="expireTimeType"
                        label="希望完成询货的时间"
                        class="pdr20"
                    >
                        <Select
                            v-model="formInline.expireTimeType"
                            class="wp100"
                            placeholder="希望完成询货的时间"
                        >
                            <Option
                                v-for="it of options.expireTimeType"
                                :key="it.text"
                                :value="it.value"
                            >
                                {{ it.text }}
                            </Option>
                        </Select>
                    </FormItem>
                </Col>
                <Col :span="12">
                    <FormItem
                        prop="priorityType"
                        label="采购优先项"
                        class="pdr20"
                    >
                        <Select
                            v-model="formInline.priorityType"
                            class="wp100"
                            placeholder="采购优先项"
                        >
                            <Option
                                v-for="it of options.priorityType"
                                :key="it.text"
                                :value="it.value"
                            >
                                {{ it.text }}
                            </Option>
                        </Select>
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col :span="12">
                    <FormItem
                        v-if="isTypeShow"
                        prop="purchasePlatform"
                        label="需走订单流程平台"
                        class="pdr20"
                    >
                        <Select
                            v-model="formInline.purchasePlatform"
                            class="wp100"
                            placeholder="需走订单流程平台"
                        >
                            <Option
                                v-for="it of options.purchasePlatform"
                                :key="it.text"
                                :value="it.value"
                            >
                                {{ it.text }}
                            </Option>
                        </Select>
                    </FormItem>
                </Col>
            </Row>

            <div class="title mb10">
                <TagTitle title="联系方式" />
            </div>
            <Row>
                <Col :span="12">
                    <FormItem prop="contactName" label="联系人" class="pdr20">
                        <Input
                            v-model="formInline.contactName"
                            class="wp100"
                            type="text"
                            placeholder="联系人"
                            clearable
                        />
                    </FormItem>
                </Col>
                <Col :span="12">
                    <FormItem
                        prop="contactPhone"
                        label="移动电话"
                        class="pdr20"
                    >
                        <Input
                            v-model="formInline.contactPhone"
                            class="wp100"
                            type="text"
                            placeholder="移动电话"
                            clearable
                        />
                    </FormItem>
                </Col>
            </Row>
            <!--            <Row>-->
            <!--                <Col :span="12">-->
            <!--                    <FormItem-->
            <!--                        prop="receiverAddressId"-->
            <!--                        label="收货地址"-->
            <!--                        class="pdr20"-->
            <!--                    >-->
            <!--                        <Select-->
            <!--                            v-model="formInline.receiverAddressId"-->
            <!--                            class="wp100"-->
            <!--                            placeholder="收货地址"-->
            <!--                        >-->
            <!--                            <Option-->
            <!--                                v-for="it of options.receiverAddressId"-->
            <!--                                :key="it.text"-->
            <!--                                :value="it.value"-->
            <!--                            >-->
            <!--                                {{ it.text }}-->
            <!--                            </Option>-->
            <!--                        </Select>-->
            <!--                    </FormItem>-->
            <!--                </Col>-->
            <!--&lt;!&ndash;                <Col :span="12">&ndash;&gt;-->
            <!--&lt;!&ndash;                    <FormItem prop="cityids" label="终端收货地址" class="pdr20">&ndash;&gt;-->
            <!--&lt;!&ndash;                        <ICity v-model="formInline.cityids" />&ndash;&gt;-->
            <!--&lt;!&ndash;                        &lt;!&ndash;    <Input&ndash;&gt;-->
            <!--&lt;!&ndash;                                v-model="formInline.receiverTerminalAddress"&ndash;&gt;-->
            <!--&lt;!&ndash;                                class="wp100"&ndash;&gt;-->
            <!--&lt;!&ndash;                                type="text"&ndash;&gt;-->
            <!--&lt;!&ndash;                                placeholder="终端收货地址"&ndash;&gt;-->
            <!--&lt;!&ndash;                            />&ndash;&gt;&ndash;&gt;-->
            <!--&lt;!&ndash;                    </FormItem>&ndash;&gt;-->
            <!--&lt;!&ndash;                </Col>&ndash;&gt;-->
            <!--            </Row>-->
            <Row>
                <Col :span="12">
                    <FormItem prop="cityids" label="收货地址" class="pdr20">
                        <ICity ref="icity" v-model="formInline.cityids" />
                        <!--    <Input
                                v-model="formInline.receiverTerminalAddress"
                                class="wp100"
                                type="text"
                                placeholder="终端收货地址"
                            />-->
                    </FormItem>
                </Col>
                <Col :span="12">
                    <FormItem
                        prop="receiverTerminalAddress"
                        label="详细地址"
                        class="pdr20"
                    >
                        <Input
                            v-model="formInline.receiverTerminalAddress"
                            class="wp100"
                            type="text"
                            placeholder="详细地址"
                        />
                    </FormItem>
                </Col>
            </Row>

            <Row>
                <Col :span="24" class="flex-center">
                    <Button class="mr10" @click="back"> 返回 </Button>
                    <Button class="mr10" type="primary" @click="save">
                        保存
                    </Button>
                    <Button class="mr10" type="primary" @click="save1">
                        发布
                    </Button>
                </Col>
            </Row>
        </Form>

        <!--        <div class="i-modals">-->
        <!--            <Modal-->
        <!--                v-model="isApproved"-->
        <!--                title="小助提示"-->
        <!--                :closable="false"-->
        <!--                :mask-closable="false"-->
        <!--                :transfer="false"-->
        <!--                footer-hide-->
        <!--            >-->
        <!--                <p class="c-red bold tc pd24">-->
        <!--                    企业工商信息认证还没有通过，无法发需求-->
        <!--                </p>-->
        <!--            </Modal>-->
        <!--        </div>-->

        <div class="i-modals" data-scroll-id="msg-type">
            <Modal
                v-model="msgType.show"
                title="小助提示"
                :closable="false"
                :mask-closable="false"
                :transfer="false"
                footer-hide
            >
                <p class="c-red bold tc pd24">
                    {{ msgType.content }}
                </p>
            </Modal>
        </div>
    </div>
</template>
<script>
import IUpload from '../../../../components/IUpload.vue';
import ICity from '../../../../components/ICity.vue';
import msgType from '@/mixins/msg.type';
import TagTitle from '@/components/TagTitle.vue';
export default {
    components: { TagTitle, IUpload, ICity },
    mixins: [msgType],
    data() {
        return {
            isApproved: false, // 审核是否通过提示
            isTypeShow: false, // 是否是 科研类型 终端企业 显示  ，企业用户 不显示
            orderType: '1',
            formInline: {
                tryOne: 0, // 是否是在来一单
                brandId: '',
                productNo: '',
                productName: '',
                purchasePlatform: null,
                priorityType: '',
                productQuantity: 1,
                expireTimeType: '',
                contactName: '',
                contactPhone: '',
                receiverAddressId: '',
                receiverTerminalAddress: '',
                unit: '',
                cityids: [],
            },
            ruleInline: {
                brandId: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (!this.formInline.brandId) {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                productNo: [
                    { required: true, message: '不能为空', trigger: 'change' },
                ],
                productName: [
                    { required: true, message: '不能为空', trigger: 'change' },
                ],
                productCategoryType: [
                    { required: true, message: '不能为空', trigger: 'change' },
                ],
                unit: [
                    { required: true, message: '不能为空', trigger: 'change' },
                ],
                specs: [
                    { required: true, message: '不能为空', trigger: 'change' },
                ],
                priorityType: [
                    { required: true, message: '不能为空', trigger: 'change' },
                ],
                productQuantity: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (!this.formInline.productQuantity) {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                cityids: [
                    {
                        required: true,
                        message: '请填写完整终端地址',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            let [province, city, region] =
                                this.formInline.cityids;
                            if (!province || !city || !region) {
                                cb(new Error('请填写完整终端地址'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                contactName: [
                    { required: true, message: '不能为空', trigger: 'change' },
                ],
                contactPhone: [
                    {
                        required: true,
                        message: '手机号格式不正确',
                        trigger: 'change',
                        validator: $util.validator.phone,
                    },
                ],
                productPic: [
                    {
                        required: true,
                        message: '图片不能为空',
                        trigger: 'change',
                    },
                ],
                expireTimeType: [
                    { required: true, message: '不能为空', trigger: 'change' },
                ],
                receiverAddressId: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (!this.formInline.receiverAddressId) {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                receiverTerminalAddress: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                    },
                ],
            },
            options: {
                receiverAddressId: [],
                brandId: [],
                brandId2: [],
                productCategoryType: [...$const.options.productCategoryType],
                priorityType: [...$const.options.priorityType],
                purchasePlatform: [...$const.options.purchasePlatform],
                expireTimeType: [...$const.options.expireTimeType],
                unitType: [...$const.options.unitType],
            },
        };
    },
    watch: {
        '$currentOrg.org.status': {
            handler(status) {
                this.isApproved =
                    typeof status === typeof 0 && status - 1 !== 0;
            },
            immediate: true,
        },
        '$currentOrg.org.type': {
            handler(type) {
                this.isTypeShow = type !== 2; // 如果 type 是 2，设置 isTypeShow 为 false
            },
            immediate: true,
        },
    },
    async mounted() {
        window.t = this;
        await this.getBrandOpts();
        await this.getAddressOpts();
        if (this.$route.query.id) {
            this.getData(this.$route.query.id);
        }
    },
    methods: {
        remoteMethod(search) {
            setTimeout(() => {
                this.options.brandId2 = this.options.brandId.filter((it) => {
                    let tv = (it.text || '').toLowerCase().trim();
                    let sv = (search || '').toLowerCase().trim();
                    return tv.startsWith(sv);
                });
            });
        },
        // 获取品牌选项
        async getBrandOpts() {
            let res = await $service.brand.list({ pageSize: -1 });

            let records = _.get(res, 'data.records') || [];

            this.options.brandId = [
                ...records.map((it) => ({ text: it.name, value: it.id })),
            ];
        },
        // 获取地址选项
        async getAddressOpts() {
            let res = await $service.memberReceiveAddress.list({
                pageSize: -1,
            });

            let records = _.get(res, 'data.records') || [];
            // this.options.receiverAddressId = [
            //     ...records.map((it) => ({
            //         text: `${it.provinceName}${it.cityName}${it.regionName}${it.detailAddress}`,
            //         // text: `${it.provinceName}/${it.cityName}/${it.regionName}/${it.detailAddress}`,
            //         value: it.id,
            //     })),
            // ];
            if (records.length > 0) {
                let address = records[0];
                this.formInline.cityids = [
                    address.province,
                    address.city,
                    address.region,
                ];
                this.$nextTick(() => {
                    this.$refs.icity.updatePropValue();
                    console.log('update', 'updaata', this.formInline.cityids);
                });
                this.formInline.receiverTerminalAddress = address.detailAddress;
            }
        },
        async save() {
            await new Promise((resolve) =>
                this.$refs.formInline.validate((valid) => valid && resolve()),
            );
            let {
                brandId = '', // 品牌名称
                productNo = '', // 货号
                productQuantity = '', // 数量
                productCategoryType = '', // 类型
                priorityType = '', // 采购优先项
                purchasePlatform = '', // 需走订单流程平台
                receiverAddressId = '', // 收货地址
                receiverTerminalAddress = '', // 终端客户地址
                contactName = '', // 联系人
                contactPhone = '', // 联系电话
                expireTimeType = '', // 商家响应时限
                cityids = [],
                // 图片
                productPic = '',

                // 名称
                productName = '',
                unit = '',
                specs = '',
            } = this.formInline;
            let [province, city, region] = cityids;
            // org覆盖
            // let {province, city, region} = _.get(this.$currentOrg, 'org') || {};

            console.log('cityids ==== ' + JSON.stringify(cityids));
            if (!province || !city || !region) {
                return this.$Message.error('请选择省市区');
            }

            let productBrand;
            for (let it of this.options.brandId) {
                if (it.value - brandId === 0) {
                    productBrand = it.text;
                }
            }

            let params = {
                id: this.formInline.id,
                contactName,
                contactPhone,
                expireTimeType,
                province,
                city,
                region,
                needItemList: [
                    {
                        brandId,
                        productBrand,
                        productCategoryType,
                        productName,
                        productNo,
                        productPic, // 图片发单
                        productQuantity,
                        unit,
                        specs, // 新增商品规格参数
                    },
                ],
                orderType: this.orderType,
                // "orgId": 0,
                priorityType,
                // purchasePlatform,
                receiverAddressId,
                // "receiverDetailAddress": "",
                receiverTerminalAddress,
                sourceType: 3, // pc=3
                // "status": ""
            };
            console.log('purchasePlatform ===== ', purchasePlatform);
            if (purchasePlatform && purchasePlatform !== 'undefined') {
                params.purchasePlatform = purchasePlatform;
            }
            console.log(params, 'params');
            // 发单
            let response = {};
            // if (params.receiverAddressId) {
            if (this.$route.query.tryOne == 1) {
                params.id = null;
                response = await $service.need.create(params);
            } else if (params.id) {
                response = await $service.need.modify(params);
            } else {
                response = await $service.need.create(params);
            }
            if (response.code !== 100000) {
                await this.$Message.error(response.msg);
            }
            this.back();
        },
        //发布
        async save1() {
            await new Promise((resolve) =>
                this.$refs.formInline.validate((valid) => valid && resolve()),
            );
            let {
                brandId = '', // 品牌名称
                productNo = '', // 货号
                productQuantity = '', // 数量
                productCategoryType = '', // 类型
                priorityType = '', // 采购优先项
                purchasePlatform = '', // 需走订单流程平台
                receiverAddressId = '', // 收货地址
                receiverTerminalAddress = '', // 终端客户地址
                contactName = '', // 联系人
                contactPhone = '', // 联系电话
                expireTimeType = '', // 商家响应时限
                unit = '', //单位
                // 图片
                productPic = '',
                cityids = [],
                // 名称
                productName = '',
                specs = '', // 规格
            } = this.formInline;
            let [province, city, region] = cityids;
            // org覆盖
            // let {province, city, region} = _.get(this.$currentOrg, 'org') || {};

            let productBrand;
            for (let it of this.options.brandId) {
                if (brandId && it.value - brandId === 0) {
                    productBrand = it.text;
                }
            }

            let params = {
                id: this.formInline.id,
                contactName,
                contactPhone,
                expireTimeType,
                province,
                city,
                region,
                needItemList: [
                    {
                        brandId,
                        productBrand,
                        productCategoryType,
                        productName,
                        productNo,
                        productPic, // 图片发单
                        productQuantity,
                        unit,
                        specs, // 新增商品规格参数
                    },
                ],
                orderType: this.orderType,
                // "orgId": 0,
                priorityType,
                // purchasePlatform,
                receiverAddressId,
                // "receiverDetailAddress": "",
                receiverTerminalAddress,
                sourceType: 3, // pc=3
                // "status": "2"
            };
            console.log(params, 'params');
            console.log('purchasePlatform ===== ', purchasePlatform);
            if (purchasePlatform && purchasePlatform !== 'undefined') {
                params.purchasePlatform = purchasePlatform;
            }
            // 发单
            let response = {};
            // if (params.receiverAddressId) {
            if (this.$route.query.tryOne == 1) {
                params.id = null;
                response = await $service.need.createAndPublish(params);
            } else if (params.id) {
                response = await $service.need.modify(params);
            } else {
                response = await $service.need.createAndPublish(params);
            }
            if (response.code !== 100000) {
                await this.$Message.error(response.message || '失败');
                return;
            }
            this.back();
        },
        async publish() {
            await new Promise((resolve) =>
                this.$refs.formInline.validate((valid) => valid && resolve()),
            );
        },
        back() {
            this.$router.push('/home/buyer/index');
        },
        async getData(id) {
            let res = await $service.need.getById({ id });
            let data = _.get(res, 'data') || {};

            let formData = {
                id: data.id,
                brandId: _.get(data, 'needItemList[0].brandId'), // 品牌名称
                productBrand: _.get(data, 'needItemList[0].productBrand'), // 品牌名称
                productNo: _.get(data, 'needItemList[0].productNo'), // 货号
                productQuantity: _.get(data, 'needItemList[0].productQuantity'), // 数量
                productCategoryType:
                    _.get(data, 'needItemList[0].productCategoryType') + '', // 类型
                priorityType: data.priorityType + '', // 采购优先项
                purchasePlatform: data.purchasePlatform + '', // 需走订单流程平台
                // receiverAddressId: data.receiverDetailAddress + '', // 收货地址
                receiverTerminalAddress: data.receiverTerminalAddress, // 终端客户地址
                contactName: data.contactName, // 联系人
                contactPhone: data.contactPhone, // 联系电话
                expireTimeType: data.expireTimeType + '', // 商家响应时限
                unit: _.get(data, 'needItemList[0].unit') + '', // 单位
                // 图片
                productPic: _.get(data, 'needItemList[0].productPic'),
                // 名称
                productName: _.get(data, 'needItemList[0].productName'),

                // addition
                needSn: data.needSn,
            };

            this.orderType = data.orderType + '';

            if (typeof formData.receiverAddressId === typeof '') {
                for (let it of this.options.receiverAddressId) {
                    let text = it.text.replaceAll(' ', '');
                    let receiverAddressId =
                        formData.receiverAddressId.replaceAll(' ', '');
                    if (
                        text === receiverAddressId ||
                        receiverAddressId.includes(text)
                    ) {
                        formData.receiverAddressId = it.value;
                        break;
                    }
                }
            }

            this.remoteMethod(formData.productBrand);
            this.formInline = { ...formData, cityids: this.formInline.cityids };
        },
    },
};
</script>
<style lang="less" scoped>
.buyer-bill-wrapper {
    padding-bottom: 60px;
    .title {
        .prefix {
            width: 20px;
            height: 20px;
            border-radius: 2px;
            padding: 2px;
        }
    }

    /deep/ .i-modals {
        .ivu-modal-mask {
            top: 88px;
            z-index: 1 !important;
        }

        .ivu-modal-wrap {
            top: 88px;
            z-index: 1 !important;
            .ivu-modal {
                top: 300px;
            }
        }
    }
}
</style>
