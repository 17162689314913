<template>
    <div>
        <div class="user-banner mb10">
            <img
                class="wp100 vertical-mid"
                src="../../../assets/img/banner/buyer.webp"
                alt=""
            />
        </div>
        <BillForm />
    </div>
</template>

<script>
import BillForm from './Bill/BillForm';
export default {
    components: { BillForm },
};
</script>
