import { createNamespacedHelpers } from 'vuex';

const { mapState, mapMutations } = createNamespacedHelpers('needGetByID');

export default {
    computed: {
        ...mapState(['$needIDData']),
        has$needIDData() {
            return !_.isEmpty(this.$needIDData);
        },
    },
    methods: {
        ...mapMutations(['set$needIDData']),
        async get$needIDData(params = {}, silent = false) {
            let res = await $service.need.getById(
                {
                    id: this.$route.query.id,
                    ...params,
                },
                { silent },
            );
            let data = _.get(res, 'data') || {};
            this.set$needIDData(data);
            return data;
        },
    },
};
